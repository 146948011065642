import Vue from "vue";
import pstatImg from "@/assets/images/pstatImg.png";
const submitOrder = {
  data() {
    return {
      messageType: "",
      returnRes: {},
      IsCheckRepeatOrder: 0,
      timeer: null,
    };
  },
  beforeDestroy() {
    clearTimeout(this.timeer);
  },
  methods: {
    copyOrder(item, bnt, tindex) {
      item.isFromList && this.setListOn(tindex);
      this.SmartStorage.remove("extdataForm");
      this.SmartStorage.remove("orderDetail");
      let items = item;
      items.itemId = "";
      items.ItemId = "";
      items.itsItemData.ItemId = "";
      this.SmartStorage.set("onlineData", items.itsItemData);
      this.SmartStorage.set("orderlistStatu", this.tabBar);
      this.SmartStorage.set("editdataForm", items);
      this.SmartStorage.remove("carLevelist");
      this.SmartStorage.remove("start_name");
      this.SmartStorage.remove("end_name");
      this.refreshHub && this.refreshHub.uninstall();
      console.log("---复制订单跳转", item);
      // this.$router.push({
      //     path: "/onlineEditOrder",
      //     query: {
      //         type: this.$route.query.isFromeAll,
      //     },
      // });
      this.$router.push(`/onlineEditOrder?type=copy`);

      // this.$router.push("/platformEditOrder");
    },
    editItemPerson(data, btn, tindex) {
      console.log(data, btn, tindex);
      this.$iMessageBox.render({
        hasInput: true,
        messageTitle: "修改实际人数",
        messageTxt: "请输入需要修改的实际人数",
        inputType: "input",
        value: data.itsItemData.qty,
        onConfirm: function(res) {
          let params = {
            itemId: data.ItemId || data.itemId,
            qty: res,
          };
          this.services.UpdateItemRealPerson(params).then((res) => {
            if (res.success) {
              data.itsItemData.qty = res.content.qty;
              this.$iToast("修改人数成功");
            }
          });
        }.bind(this),
      });
    },
    rebackOrder(data, btn, tindex) {
      console.log(
        "--一键反正前",
        JSON.parse(JSON.stringify(JSON.parse(data.ItemData || data.itemData)))
      );
      // JSON.parse(JSON.stringify(data.ItemData))
      data.isFromList && this.setListOn(tindex);
      this.SmartStorage.set("orderlistStatu", this.tabBar);
      this.SmartStorage.remove("extdataForm");
      this.SmartStorage.remove("orderDetail");
      let items = data.itsItemData;
      data.itemId = "";
      data.ItemId = "";
      items.itemId = "";
      items.ItemId = "";
      items.end_address = [
        items.start_address,
        (items.start_address = items.end_address),
      ][0];
      items.destAddress = [
        items.puAddress,
        (items.puAddress = items.destAddress),
      ][0];
      items.destCity = [items.puCity, (items.puCity = items.destCity)][0];
      items.end_city = [
        items.start_city,
        (items.start_city = items.end_city),
      ][0];
      items.end_name = [
        items.start_name,
        (items.start_name = items.end_name),
      ][0];
      items.end_type = [
        items.start_type,
        (items.start_type = items.end_type),
      ][0];
      items.endCityId = [
        items.startCityId,
        (items.startCityId = items.endCityId),
      ][0];
      items.tlat = [items.flat, (items.flat = items.tlat)][0];
      items.flng = [items.tlng, (items.tlng = items.flng)][0];
      switch (items.carRule) {
        case 4:
          items.carRule = 3;
          items.carRule_txt = "送机";
          items.flight_num = "";
          items.flt = "";
          items.departure_time = null;
          break;
        case 3:
          items.carRule = 4;
          items.carRule_txt = "接机";
          items.flight_num = "";
          items.flt = "";
          items.departure_time = null;
      }
      data.itemData = JSON.stringify(items);
      data.ItemData = JSON.stringify(items);
      data.itsItemData = items;
      this.SmartStorage.set("editdataForm", data);
      this.SmartStorage.remove("carLevelist");
      this.SmartStorage.remove("start_name");
      this.SmartStorage.remove("end_name");
      this.refreshHub && this.refreshHub.uninstall();
      console.log(
        "--一键返程后",
        this.SmartStorage.get("editdataForm").itsItemData
      );
      this.$router.push({
        path: "/onlineEditOrder?type=editItem",
      });
      // this.$router.push("/platformEditOrder");
    },
    //打开滴滴h5页面
    async ESWebappUrl(itemId, typeCallBack, IsMergeCar) {
      let param = {
        itemId: itemId,
      };
      let getUrl = IsMergeCar ? "ESWebappCarMergeUrl" : "ESWebappUrl";
      this.services[getUrl](param).then((res) => {
        if (res.success) {
          this.typeCallBack = typeCallBack;
          this.itemIdData = itemId;
          this.iframeUrl = res.content.url;
          // this.showDiDiH5 = true;
          this.setRouteListeners("didiH5Pageback"); //保存当前路由返回事件记录
          this.$root.$eventHub.$on("didiH5Pageback", (data) => {
            this.didiH5Pageback();
            this.$root.$eventHub.$off("didiH5Pageback");
          });
          let arr = ["common.diditaxi.com.cn", "sharetrips"];
          const container = this.$cookies.get("container");
          if (this.SmartStorage.get("isH5")) {
            this.goBrowserLoader(res.content.url);
          } else {
            switch (container) {
              case "browser":
                this.goBrowserLoader(res.content.url);
                break;
              case "h5":
                this.goBrowserLoader(res.content.url);
                break;
              case "native":
              default:
                this.gosafetyCenter(
                  "isDIDI",
                  res.content.url,
                  typeCallBack ? this.ESWebappBack : {},
                  arr
                );
            }
          }
          // this.SmartStorage.get("isH5") ? this.goBrowserLoader(res.content.url) : this.gosafetyCenter('isDIDI', res.content.url, typeCallBack ? this.ESWebappBack : {}, arr)
        } else if (res.code == 50202) {
          this.isDiDiqiye = true;
          this.iframeUrl = res.content;
          this.messageType = "didiTips";
          this.messageTxt = res.msg;
          this.$refs.tipsMsg.openPop();
          this.isloding = true;
        }
        this.isloding = true;
      });
    },
    confirmOrder(data) {
      let _this = this;
      this.services
        .OnlineConfirmOrder({ ItemId: data.ItemId || data.itemId })
        .then((res) => {
          if (res.success) {
            _this.isloding = true;
            _this.$iToast("确认订单成功");
            if (_this.$route.query.type == "viewOrder") {
              _this.$router.go(-1);
            }
            _this.refrashData();
          }
        });
    },
    goBrowserLoader(url) {
      this.iframeUrl = url;
      this.showDiDiH5 = true;
      // window.location.href = url
    },
    //打开高德h5
    async openGaode(itemId, typeCallBack) {
      let params = {
        itemId: itemId,
      };
      this.services.GetOrderUrl(params).then((res) => {
        if (res.success) {
          this.itemIdData = itemId;
          this.typeCallBack = typeCallBack;
          let gaodearry = ["amap.com", "smartmice", "#/publish"];
          const container = this.$cookies.get("container");
          switch (container) {
            case "browser":
              this.goBrowserLoader(res.content.url);
              break;
            case "h5":
              this.goBrowserLoader(res.content.url);
              break;
            case "native":
            default:
              this.gosafetyCenter(
                "",
                res.content.url,
                typeCallBack ? this.ESWebappBack : {},
                gaodearry
              );
          }
          // this.SmartStorage.get("isH5") ? this.goBrowserLoader(res.content.url) : this.gosafetyCenter("", res.content.url, typeCallBack ? this.ESWebappBack : {}, gaodearry);
        }
        this.isloding = true;
      });
    },
    async ESWebappBack() {
      console.log(this.typeCallBack);
      setTimeout(() => {
        if (this.typeCallBack == "index") {
          !this.$route.query.isApproval && this.StatByStatus([]);
        } else {
          this.$router.push("/platformIndex");
          // let params = {
          //     proposalId: this.SmartStorage.get("proposalId"),
          //     itemId: this.itemIdData
          // };
          // this.services.GetOnlineDetail(params).then((res) => {
          //     if (res.success) {
          //         res.content.extOrderId && this.$router.push("/platformIndex");
          //     }

          // })
        }
      }, 2000);
    },
    updateEndOrder(item) {
      console.log(item);
      item.itsItemData = item.itsItemData
        ? item.itsItemData
        : JSON.parse(item.ItemData);
      item.itemId = item.itemId ? item.itemId : item.ItemId;
      item.extOrderId = item.extOrderId ? item.extOrderId : item.ExtOrderId;
      this.$refs.mapPop.open(
        {
          city: item.itsItemData.destCity,
          curName: item.itsItemData.end_address,
          longitude: item.itsItemData.tlng,
          latitude: item.itsItemData.tlat,
          itemId: item.itemId,
          orderId: item.extOrderId,
        },
        true,
        true
      );
    },
    //刷新订单
    async refreshOrder(item) {
      this.isloding = false;
      let param = {
        proposalId: item.proposalId,
        itemId: item.itemId,
      };
      let _this = this;
      this.services.OnlineRefreshOrder(param).then((res) => {
        if (res.success) {
          _this.isloding = true;
          _this.$iToast("刷新订单成功");
          _this.QueryOnlineOrder(true);
          // _this.dataList&&_this.dataList.length > 0 && _this.dataList.forEach((element, index) => {
          //     if (element.itemId === item.itemId) {
          //         _this.dataList[index] = res.content;
          //         _this.dataList[index].itsItemData = JSON.parse(res.content.ItemData)
          //         _this.$forceUpdate();
          //     }
          // });
        } else if (res.code == 15) {
          // _this.messageBox(res);
          _this.$iToast(res.msg);
          _this.isloding = true;
        } else {
          _this.$iToast(res.msg);
          _this.isloding = true;
        }
      });
    },
    //获取取消原因列表
    async getCancelReson(keyCode) {
      console.log(keyCode);
      let params = {
        keyCode: keyCode,
      };
      let _this = this;
      this.services.queryDict(params).then((res) => {
        if (res.success) {
          _this.isloding = true;
          _this.cancelReson = res.content;
        }
      });
    },
    //点击确定取消订单
    confirmCancel() {
      this.isloding = false;
      if (this.reason) {
        // this.$iDelay(() => {
        //     this.CancelOnlineOrder();
        // }, 200);
        this.CancelOnlineOrder();
        this.$refs.cancelResonPop.openPop();
      } else {
        this.$iToast("请选择取消原因");
        this.isloding = true;
      }
    },
    // canseOrderShenzhou() {
    //     this.getCancelReson("ShenZhou.CancelReason");
    //     this.$refs.cancelResonPop.openPop();
    // },
    cancelMthorde(type) {
      // if (this.isGoh5(this.cancelItem, false, true)) {
      this.getCancelReson("ShenZhou.CancelReason");
      this.$refs.cancelResonPop.openPop();
      // }
    },
    CheckRepeatOrder() {
      const shadeBox = document.getElementsByClassName("v-modal")[0];
      if (shadeBox) {
        shadeBox.style.zIndex = "2000";
      }
      this.IsCheckRepeatOrder = 0;
      console.log(111);
      this.IsCheckRepeatOrderType
        ? this.saveItemP(this.saveType)
        : this.createBatchOrder("", 1);
    },
    //需求重复返回
    epeatOrder(res, type) {
      const shadeBox = document.getElementsByClassName("v-modal")[0];
      if (shadeBox) {
        shadeBox.style.zIndex = "50000";
      }
      this.IsCheckRepeatOrderType = type;
      this.returnRes = res;
      this.messageType = "CheckRepeatOrder";
      this.messageTxt = res.msg;
      this.$refs.tipsMsg.openPop();
    },
    mapSetReceivedOrder() {
      this.planDrivingLine && this.planDrivingLine.clear();
      this.map.clearMap();
      let position = new AMap.LngLat(
        this.params?.ItemData?.flng,
        this.params?.ItemData?.flat
      );
      this.map.setCenter(position);
      let circle = new AMap.Circle({
        center: position, // 圆心位置
        radius: 1000, // 圆半径
        strokeColor: "#bbe7e4",
        fillColor: "#bbe7e4", // 圆形填充颜色
      });
      let pMarker = new AMap.Marker({
        map: this.map,
        position: position,
        offset: new AMap.Pixel(0, -20),
        icon: new AMap.Icon({
          image: pstatImg,
          imageOffset: new AMap.Pixel(0, 0),
          imageSize: new AMap.Size(20, 46),
        }),
      });
      this.map.add(pMarker);
      this.map.add(circle);
      this.map.setZoom(13);
    },
    scrollTop() {
      let priceCouponBox = document.getElementsByClassName("priceCouponBox")[0];
      let pricescrollBox = document.getElementsByClassName(
        "priceCouponContent"
      )[0];
      console.log(this.$refs.pricescrollBox.scrollTop);
      this.$refs.pricescrollBox.scrollTop = 0;
      priceCouponBox.style.height = this.isReceivedOrder ? "40%" : "50%";
      pricescrollBox.style.height = this.isReceivedOrder ? "220px" : "260px";
      this.mapSetReceivedOrder();
    },
    SaveCarProtocol() {
      let param = {
        ProtocolCode: "CarProtocol",
        BusinessId: this.params.itemId,
        ProposalId: this.SmartStorage.get("proposalId"),
        ProtocolContent: this.messageTxt,
      };
      this.services.SaveCarProtocol(param).then((res) => {
        if (res.success) {
          this.createBatchOrder(this.params, 1, true);
        }
      });
    },
    //強制取消订单
    async forceCancelOrder(item) {
      this.cancelItem = item;
      this.force = true;
      this.cancelMthorde("强制");
    },
    //取消订单
    cancelOrder(item) {
      console.log(item);
      this.cancelItem = item;
      this.force = false;
      this.isloding = false;
      this.$iDelay(() => {
        this.cancelMthorde("");
      }, 400);
    },
    MessageBtn() {
      console.log(this.messageType);
      switch (this.messageType) {
        case "CarProtocol":
          this.SaveCarProtocol();
          break;
        case "gosafetyCenter":
          this.SmartStorage.get("isH5")
            ? (window.location.href = this.returnRes.content)
            : this.gosafetyCenter(
                "",
                this.returnRes.content,
                this.getPagingList
              );
          break;
        case "didiTips":
          this.goBrowserLoader(this.iframeUrl);
          break;
        case "cancelItem":
          this.CancelOnlineOrder(true);
          break;
        default:
          this[this.messageType]();
          break;
      }
      // this.messageType == "gosafetyCenter" ?
      //     (this.SmartStorage.get("isH5") ? window.location.href = this.returnRes.content : this.gosafetyCenter("", this.returnRes.content, this.getPagingList)) : this[this.messageType]();
    },
    CancelOnlineOrder(e) {
      this.canselect = false;
      let param = {
        force: this.force,
        proposalId: this.SmartStorage.get("proposalId"),
        itemId:
          this.cancelItem.itemId ||
          this.cancelItem.ItemId ||
          this.cancelItem.ItemData.itemId,
        reason: this.reason,
        cancelReason: this.otherReason ? this.otherReason : this.reason,
        OrderNo: this.cancelItem.ExtOrderId,
        IsInquiry: e ? false : true,
      };
      this.services.CancelItem(param).then((res) => {
        this.canselect = true;
        if (res.code == 201) {
          this.isloding = true;
          this.messageType = "cancelItem";
          this.messageTxt = res.content;
          this.$refs.tipsMsg.openPop();
          return;
        }
        if (res.success) {
          this.$iToast("取消订单成功");
          this.isloding = true;
          let _this = this;
          this.timeer = setTimeout(function() {
            _this.cancelItem && _this.cancelItem.isFromList
              ? _this.refrashData()
              : _this.getOrderGetDetail();
            _this.mapSetReceivedOrder();
          }, 600);
        } else if (res.code == 15) {
          this.returnRes = res;
          this.isloding = true;
          this.messageType = "gosafetyCenter";
          this.messageTxt = res.msg + ",请重新授权";
          this.$refs.tipsMsg.openPop();
        } else {
          this.$iToast(res.msg);
          this.isloding = true;
        }
      });
    },
    deleteItemMethode() {
      let param = { itemId: this.clickItem.itemId || this.clickItem.ItemId };
      this.services.OnlineDeleteItem(param).then((res) => {
        if (res.success) {
          this.$iToast("删除订单需求成功");
          this.refrashData(true);
          // this.StatByStatus(true) ? this.StatByStatus(true) : this.$router.push("/onlineRental");
        }
      });
    },
    //删除订单需求
    deleteItem(data) {
      this.clickItem = data;
      this.messageType = "deleteItemMethode";
      this.messageTxt = "确定删除订单吗？";
      this.$refs.tipsMsg.openPop();
    },

    async createOrder(item) {
      let params = {
        itemId: item.itemId || item.ItemId,
        tenant_id: this.SmartStorage.get("tenant_id"),
      };
      this.services.OnlineSubmitItem(params).then((res) => {
        if (res.success) {
          this.StatByStatus([]);
          this.QueryOnlineOrder(true);
        }
      });
    },
  },
};
Vue.mixin(submitOrder);

export default submitOrder;
