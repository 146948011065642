<template>
  <div>
    <Popup ref="pop" :clickClose="true">
      <div slot="popContent">
        <div class="cateGContent">
          <div class="cateGHead">车型详情</div>
        </div>
        <div class="cateGList">
          <ul class="carStyles">
            <li v-for="(item,index) in cancelReson" :key="index" class="cancelResonBox" @click.stop="canselect&&selectReson(item,index)">
              <span class="styleIcon">
                <svg class="icon" aria-hidden="true">
                  <use :xlink:href="flag===index?'#iconselected':'#iconselect'" />
                </svg>
              </span>
              <div class="priceContent">{{item.txt}}</div>
            </li>
          </ul>
          <textarea v-if="isOther" v-model="otherReason" class="cancelTxt" placeholder="请输入取消原因"></textarea>
        </div>
        <div>
          <a @click="closePop" class="closePopBtn">{{isOther?'确定':'取消'}}</a>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
export default {
  props: ["cancelReson","canselect"],
  data() {
    return {
      flag: null,
      reason:{},
      isOther:false,
      otherReason:''
    };
  },
  methods: {
    selectReson(item, index) {
      this.flag = index;
      this.reason=item;
      if(item.val=='其他取消原因'){
        console.log(item)
        this.isOther=true;
      }else{
        this.isOther=false;
        this.$set(this.$parent,'reason', item.val);
        this.$parent.confirmCancel();
      }
    //   this.$set(this.$parent,'reason', item.val);
    // //   this.reason=item.val;
    //   this.$parent.confirmCancel();
    },
    closePop() {
      if(this.isOther&& !this.otherReason){
        this.$iToast("请输入具体取消原因");
        return false;
      }
      if(this.isOther){
        this.$set(this.$parent,'reason', this.reason.val);
        this.$set(this.$parent,'otherReason', this.otherReason);
        this.$parent.confirmCancel();
      }else{
         this.$refs.pop.changePop();
      }
    },
    openPop() {
      this.flag=null;
      this.$refs.pop.changePop();
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/platform/index.scss";
</style>