<template>
  <div>
    <iHeader
      backType="blank"
      :headStyle="['msd', 'dsm'].includes($cookies.get('tenant'))?{
          background: '#fff',
          color: '#13161B',
        }: {
          background: 'var(--themeColor)',
          color: '#fff',
        }"
      :backEvent="true"
      @back-event="closeWebview()"
      :back="isbackBtn"
      v-if="!SmartStorage.get('viewOnly')"
    >
      <div slot="headText">
        <span class="head-txt">
          {{isOnline ? '线上租车' : '网约车订单'}}
        </span>
      </div>
      <template v-slot:rightBtn v-if="isShowBatchApprovalBtn">
        <div @click="toBatchApproval()">批量确认</div>
      </template>
    </iHeader>
    <div class="opSearchRow" v-else>
      <Svgs
        color="#13161B"
        classes="opChooseList"
        name="iconfanhui"
        @click.native="closeWebview()"
      />
      <SearchRow
        @search-call="onSearch"
        width="1.85rem"
        placeholder="请输入PR编号/乘车人/乘车人手机号"
        v-model="searchInput"
      ></SearchRow>
      <Svgs
        color="#13161B"
        classes="opSearchkefu"
        name="iconkefu"
        @click.native="openIhelper"
      />
    </div>
    <div class="tabBox" v-if="!isOnlineStatus">
      <div class="leftFilter" v-if="!isOnline" @click="openStatusPop()">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-shaixuan" />
        </svg>
      </div>
      <div :class="isOnline ? ' rightTab rightTab2' : 'rightTab'">
        <li
          @click="changeTabs(btn,tindex)"
          :style="orderTabs.length==4?{'width' : '25%'}:{   'width' : '28%'}"
          :class="[tabBar.Txt==btn.Txt && 'selectedTab']"
          v-for="(btn, tindex) in orderTabs"
          :key="tindex"
        >
          <div>{{ btn.Txt }}({{ btn.count }})</div>
          <span></span>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: [
    "StatByStatus",
    "closeWebview",
    "openStatusPop",
    "changeTab",
    "orderTabs",
    "filterDate",
    "tabBar",
    "isOnline",
    "isOnlineStatus"
  ],
  data() {
    return {
      tabBtn:{},
      searchInput: "",
      isbackBtn: true,
      isTwoDaysLater:false,
    };
  },
  computed:{
    isShowBatchApprovalBtn(){
      return ['novartis'].includes(this.$cookies.get('tenant'))&&this.SmartStorage.get('eventData').extData.isLargeMeeting&&this.SmartStorage.get('eventData').userId==this.$cookies.get('userId')&&this.isTwoDaysLater;
    }
  },
  created() {
    if(['novartis'].includes(this.$cookies.get('tenant'))){
    // 山德士的批量审批按钮显示需求
    let data1=moment(this.SmartStorage.get('eventData').dtEnd);//会议结束日期
    let date2=moment();//当前日期
    let day = date2.diff(data1, 'day');
    this.isTwoDaysLater=day>=1;
    console.log(' this.isTwoDaysLater',this.isTwoDaysLater);
    }
   
  },
  methods: {
    changeTabs(btn,tindex){
      this.tabBtn=btn;
      let rightTab=document.getElementsByClassName('rightTab')[0];
      rightTab.scrollLeft=tindex>2?200:0;
      this.setListOn(0)
      this.changeTab(btn, true,this.filterDate)
    },
    onSearch(){
      console.log(this.searchInput);
      let data = this.searchInput?[
          {
            Passenger_name:{
              $regex: this.searchInput
            } 
          },
          {
            Passenger_phone: {
              $regex: this.searchInput
            } 
          },
          {
            ExtNum: {
              $regex: this.searchInput
            } 
          },
        ]:[];
      this.StatByStatus(data,true)
    },
    openIhelper() {
      let appData = {
        webappAlias: {
          urlKey: "webapp-ihelper",
          type: "insideWebsite",
          path: "",
          params: "type=customServer",
        },
        callback: this.viewBack,
      };
      this.$publicFun.openWebApplication(appData);
    },
    toBatchApproval(){
      this.$router.push({
        name: 'batchApproval',
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.rightTab2{
  width: 98%!important;
  li{
    width: 22%!important;
  }
}
/deep/.rightBtn{
div{
  font-size: .13rem;
}
}
</style>