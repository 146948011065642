<template>
  <div class="statusPopMain">
    <Popup ref="pop"  position="Top"  >
      <div slot="popContent"  style="pointer-events: all;">
        
        <div class="cateGList">
          <platformHead
            :openStatusPop="openStatusPop"
            :closeWebview="closeWebview"
            :changeTab="tabClick"
            :orderTabs="orderTabs"
            :StatByStatus="StatByStatus"
            :tabBar="tabBar"
          ></platformHead>
          <div class="statuList">
            <p>供应商</p>
            <div class="supplier_list">
              <a
                :class="flagBtn.val == item.val && 'choiseBtn'"
                @click="choseType(item, index, 1)"
                v-for="(item, index) in btnList"
                :key="index"
                >{{ item.txt }}</a
              >
            </div>
            <p>订单类型</p>
            <div>
              <a
                :class="flagBtnType.val == itemtype.val && 'choiseBtn'"
                @click="choseType(itemtype, indext, 2)"
                v-for="(itemtype, indext) in typeBtnList"
                :key="indext"
                >{{ itemtype.txt }}</a
              >
            </div>
          </div>
          <div class="statuBtns">
            <a @click="resetDatas()">重置</a>
            <a class="finishBtn" @click="choiseFinish()">完成</a>
          </div>
          </div>
        </div>
        <!-- <div class="cateGContent">
          <div class="cateGHead"></div>
        </div> -->
     
    </Popup>
  </div>
</template>

<script>
import platformHead from "@/views/common/platform/platformHead";
export default {
  props: [
    "StatByStatus",
    "closeWebview",
    "openStatusPop",
    "changeTab",
    "orderTabs",
    "tabBar",
  ],
  data() {
    return {
      choiseTab: {},
      flagBtnType: {},
      flagBtn: {},
      typeBtnList: [
        {
          val: "1",
          txt: "立即叫车",
        },
        {
          val: "2",
          txt: "预约叫车",
        },
      ],
      btnList: [
        {
          val: "CaoCao",
          txt: "曹操出行",
        },
        {
          val: "ShenZhou",
          txt: "神州专车",
        },
        {
          val: "DiDi",
          txt: "滴滴出行",
        },
        {
          val: "EHI",
          txt: "一嗨出行",
        },
        {
          val: "GaoDe",
          txt: "高德打车",
        },
      ],
    };
  },
  components: { platformHead },
  methods: {
    //popup点击空白处关闭
    handleClick(e) {
      let event = e || window.event;
      const target = event.target;
      let cateGList=document.getElementsByClassName("cateGList")[0];
      if(!cateGList.contains(target)){
        this.$refs.pop.changePop();
      }
    },
    openPop(data) {
      this.choiseTab = data;
      this.$refs.pop.changePop();
      setTimeout(() => {
        let _this=this;
        let shadeTop=document.getElementsByClassName("popContent")[0];
        shadeTop.addEventListener('click', _this.handleClick, false);
      }, 500);
    },
    choseType(data, index, type) {
      if (type == 1) {
        this.flagBtn = data;
      } else {
        this.flagBtnType = data;
      }
    },
    choiseFinish() {
      let filter = {
        SourceCode: this.flagBtn.val,
        Rule: this.flagBtnType.txt,
      };
      console.log(this.choiseTab);
      this.changeTab(this.choiseTab, false, filter);
      this.$refs.pop.changePop();
    },
    resetDatas() {
      this.flagBtn = {};
      this.flagBtnType = {};
      // this.$refs.pop.changePop();
    },
    tabClick(data) {
      console.log(data);
      this.choiseTab = data;
      this.changeTab(data, true);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/platform/index.scss";
</style>
