<template>
  <div>
    <platformHead :openStatusPop="openStatusPop" :closeWebview="closeWebview" :filterDate="filterDate" :isOnlineStatus="this.SmartStorage.get('eventData').statusTxt == '计划草稿'"
      :changeTab="changeTab" :isOnline="true" :orderTabs="orderTabs" :StatByStatus="StatByStatus" :tabBar="tabBar"></platformHead>
      <p v-if="this.SmartStorage.get('eventData').statusTxt == '计划草稿'" class="online_title">租车需求</p>
    <ScrollBox class="opOrderListScroll" @on-top="scrollTop" @on-bottom="scrollBottom" :loadType="loadType">
      <template v-slot:scrollBox>
        <div v-if="tips" class="platformTips" v-html="tips">
        </div>
        <div v-if="dataList.length" class="contentBox">
          <ul>
            <li v-for="(item, index) in dataList" :key="index" @click="viewOrder(item, index)" :class="[
              flag == index && 'choiseOp',
              backListOn == index && 'opHomeOn',
            ]" :id="'opOrderList' + index">
              <a :id="'listHref' + index" :href="'#opOrderList' + index" style="display: none" @click="stopSt"></a>
              <orderListItem :item="item" :showCallBox="showCallBox" :isloding="isloding" :dropDown="dropDown"
                :btnClick="btnClick" :index="index" :tabBar="tabBar"></orderListItem>
            </li>
          </ul>
        </div>
        <Skeleton v-if="isFirst" :skList="skList" />
        <NoData v-if="!dataList.length && loadType == '' && !isFirst"></NoData>
      </template>
    </ScrollBox>
    <AddButton v-if="!this.SmartStorage.get('viewOnly') && checkCanNewItem.show" :color="$publicFun.setColor()"
      @click="checkItem(true)"></AddButton>
    <transition name="slide-fade">
      <callBox v-if="isShowCallBox" :choiseItem="choiseItem" :showCallBox="showCallBox"></callBox>
    </transition>
    <cancelResonPop ref="cancelResonPop" :canselect="canselect" :cancelReson="cancelReson"></cancelResonPop>
    <MessageBox ref="tipsMsg" type="tips" :messageTxt="messageTxt" @on-ok="MessageBtn()"></MessageBox>
    <Map ref="mapPop" @close="refrashData" />
    <statusPop ref="statusPop" :openStatusPop="openStatusPop" :closeWebview="closeWebview" :changeTab="changeTab"
      :orderTabs="orderTabs" :StatByStatus="StatByStatus" :tabBar="tabBar"></statusPop>
    <projectGrade ref="projectGrade" :refrashData="refrashData"></projectGrade>
    <returnBox :retype="'carRemark'" @closeDialog="isCarRemarkDialog = false" @quotePiece="SaveUseCarRemark"
      :isloding="isloding" :showBtn="showBtn" :carRemark="clickItem.itsItemData.useCarRemark" v-if="isCarRemarkDialog">
    </returnBox>
    <browserLoader :iframeUrl="iframeUrl" v-if="showDiDiH5" :h5GoClose="h5GoClose"></browserLoader>
  </div>
</template>
<script>
import browserLoader from "@/views/common/platform/browserLoader";
import orderListItem from "@/views/common/orderListItem";
import returnBox from "@/views/common/returnBox";
import choiseExpenseItem from "@/views/controllers/choiseExpenseItem.js";
import filterEmoji from "@/views/controllers/filterEmoji.js";
import statusPop from "@/views/common/platform/statusPop";
import Map from "@/views/common/map/map";
import platformHead from "@/views/common/platform/platformHead";
import callBox from "@/views/common/msd/callBox";
import cancelResonPop from "@/views/common/platform/onlineCancelResonPop";
import projectGrade from "@/views/common/projectGrade/projectGrade";
import submitOrder from "@/views/controllers/onlineSubmitOrder.js";
export default {
  mixins: [submitOrder, filterEmoji, choiseExpenseItem],
  data() {
    return {
      tips: '',
      iframeUrl: "",
      showDiDiH5: false,
      isShowCallBox: false,
      isCarRemarkDialog: false,
      chioseIndex: 0,
      flag: -1,
      showBtn: true,
      dropDown: false,
      isloding: true,
      total: 0,
      loadType: "",
      skList: [
        {
          width: "65%",
          row: 5,
        },
        {
          width: "20%",
          position: "right",
        },
        {
          width: "40%",
          inline: true,
        },
        {
          width: "50%",
        },
        {
          width: "30%",
          ml: ".1rem",
        },
        {
          width: "15%",
          inline: true,
        },
        {
          width: "100%",
          inline: true,
        },
        {
          width: "0",
          inline: true,
        },
      ],
      filterDate: {},
      searchDate: [],
      isFirst: true,
      orderTabs: [],
      clickItem: {},
      reason: "",
      otherReason: "",
      cancelReson: [],
      canselect: true,
      messageType: "",
      messageTxt: "",
      checkCanNewItem: {},
      pageIndex: 1,
      pageSize: 10,
      dataList: [],
      tabBar: {},
    };
  },
  async created() {
    this.redirectRouteListeners(); //记录全局路由轨迹
    this.$root.$eventHub.$on("closeWebview", (data) => {
      this.closeWebview();
    });
    let tabBar = this.SmartStorage.get("orderlistStatu");
    if (tabBar) {
      this.tabBar = tabBar;
    }
    !this.SmartStorage.get("viewOnly") && this.checkItem();
    // this.installSingler();
    this.StatByStatus([]);
  },
  components: {
    Map,
    orderListItem,
    cancelResonPop,
    statusPop,
    platformHead,
    projectGrade,
    returnBox,
    callBox,
    browserLoader
  },
  beforeDestroy() {
    this.dataList = null;
    this.$root.$eventHub.$off("closeWebview")
    // this.refreshHub.uninstall();
  },
  methods: {
    showCallBox(item) {
      console.log(item)
      this.choiseItem = item;
      this.isShowCallBox = !this.isShowCallBox;
    },
    h5GoClose() {
      this.showDiDiH5 = false;
      this.StatByStatus(this.searchDate);
    },
    /**
     * 打开评分页面
     */
    goSurvayPage(item, btn) {
      console.log(btn);
      if (btn.status == 3) {
        this.$iToast("订单结束14天后无法填写满意度调查");
        return;
      } else {
        this.$refs.projectGrade.showPopup(item, btn);
      }
    },
    scrollTop(filter) {
      this.loadType = "top";
      this.pageIndex = 1;
      if (filter) {
        this.dataList = [];
      }
      this.StatByStatus(this.searchDate);
    },
    scrollBottom() {
      if (this.pageSize * this.pageIndex >= this.total) {
        this.loadType = "none";
      } else {
        this.loadType = "bottom";
        this.pageIndex += 1;
        this.QueryOnlineOrder();
      }
    },
    async Refresh() {
      let params = {
        proposalId: this.SmartStorage.get("proposalId"),
      };
      this.services.Refresh(params).then(function (res) {
        if (res.success) {
          console.log(res);
        }
      });
    },
    refrashData(data) {
      this.StatByStatus(this.searchDate);
    },
    async StatByStatus(searchDate, isSearch) {
      // await this.Refresh();
      this.setParams(searchDate);
      this.searchDate = searchDate;
      if (isSearch) {
        this.pageIndex = 1;
      }
      let _this = this;
      this.services.StatByStatus(this.params).then(function (res) {
        if (res.success) {
          _this.orderTabs = _this.SmartStorage.get("viewOnly")
            ? res.content.filter((item) => item.Txt != "未下单")
            : res.content;
          _this.tabBar = _this.tabBar.Txt ? _this.tabBar : _this.orderTabs[0];
          _this.tips = res.content[0].Tips
          _this.QueryOnlineOrder(true);
        }
      });
    },
    setParams(searchDate) {
      this.params = {
        Channel: 6,
        Filter: {
          SourceCode: this.filterDate.SourceCode,
        },
      };
      if (this.SmartStorage.get("proposalId")) {
        this.params.Filter.ProposalId = this.SmartStorage.get("proposalId");
      }
      this.params.isAuthorized =
        (this.$cookies.get("tenant") == "msd" ||
          this.SmartStorage.get("eventData").isFromMore)
          ? true
          : false;
      if (this.filterDate && this.filterDate.Rule) {
        if (this.filterDate.Rule == "立即叫车") {
          // this.params.Filter.Rule = "立即叫车";
          this.params.Filter.$or = [
            {
              Rule: "立即叫车",
            },
            {
              TypeTxt: "立即叫车",
            },
          ];
        } else {
          this.params.Filter.$or = [
            {
              Rule: "预约叫车",
            },
            {
              Rule: "接机",
            },
            {
              Rule: "送机",
            },
            {
              Rule: "包天",
            },
            {
              TypeTxt: "预约叫车",
            },
          ];
        }
      }
      if (searchDate && searchDate.length > 0) {
        this.params.Filter.$or = searchDate;
      }
    },
    openStatusPop() {
      this.$refs.statusPop.openPop(this.tabBar);
    },
    btnClick(item, btn, index) {
      this.$iDelay(() => {
        // item.itsItemData = JSON.parse(item.ItemData);
        // item.itemData = item.ItemData;
        // item.itsItemData = JSON.parse(item.ItemData);
        item.itsExtOrderData =
          item.ExtOrderData && JSON.parse(item.ExtOrderData);
        item.itemId = item.ItemId;
        item.proposalId = item.ProposalId;
        item.extOrderId = item.ExtOrderId;
        item.isFromList = true;
        this.clickItem = item;
        this[btn.Processor](item, btn, index);
      }, 200);
    },
    async editItem(item, btn, index) {
      if (!item && this.checkCanNewItem.forbidden) {
        this.checkCanNewItem.tooltip
          ? this.$iToast(this.checkCanNewItem.tooltip)
          : this.$iToast("暂时不能添加");
      } else {
        this.SmartStorage.set("editdataForm", item ? item : { isFromList: false });
        index && this.setListOn(index);
        this.SmartStorage.remove("onlineData");
        this.$router.push("/onlineEditOrder?type=editItem");
        this.SmartStorage.set("orderlistStatu", this.tabBar);
      }
    },
    viewOrder(item, index) {
      item.itemId = item.ItemId;
      this.setListOn(index);
      this.SmartStorage.set("orderlistStatu", this.tabBar);
      item.itsItemData.itemId = item.ItemId;
      item.itsItemData.ItemId = item.ItemId;
      this.SmartStorage.set("orderDetail", item);
      localStorage && localStorage.setItem("_AMap_AMap.IndoorMap", "");
      this.SmartStorage.remove("onlineData");
      this.SmartStorage.set('onlineData', item.itsItemData)
      this.$router.push({
        path: "/onlinePlatformPriceCoupon",
        query: {
            type: 'viewOrder'
        }, 
      });
      //  item.Status==0&&this.SmartStorage.set("editdataForm", item)
      // item.Status == 2 ? this.SmartStorage.set('onlineData', item.itsItemData) :
      // this.refreshHub.uninstall();
    },
    changeTab(data, isStatus, filterDate) {
      console.log('=============',data);
      this.tips = data.Tips
      this.$iDelay(() => {
        this.dropDown = !this.dropDown;
        this.tabBar = data;
        let _this = this;
        if (!isStatus) {
          this.filterDate = filterDate;
          this.StatByStatus(this.searchDate, true);
        } else {
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          this.pageIndex = 1;
          this.QueryOnlineOrder(true);
        }
      }, 200);
    },
    //消息监听
    installSingler() {
      var that = this;
      that.refreshHub = new this.iSignalr({
        hubName: "SmartHub",
        queryParams: "group=" + this.SmartStorage.get("proposalId"),
        listeners: [
          {
            methodName: "OrderStatusChanged",
            method: function (data) {
              console.log(data);
              if (data) {
                that.StatByStatus(that.searchDate);
              }
            },
          },
        ],
        host: process.env.VUE_APP_SIGNALER,
      });
      that.refreshHub.install();
    },
    redirectRouteListeners() {
      this.SmartStorage.set("routeListeners", ["closeWebview"]);
    },
    // 退出城市车队页面
    closeWebview() {
      const container = this.$cookies.get("container");
      switch (container) {
        case "browser":
          this._browserBack()
          break;
        case "h5":
          this._h5Back()
          break;
        case "native":
        default:
          this.closeAppWithReload();
      }
    },
    //是否可以添加需求
    async checkItem(flag, item) {
      let param = {
        proposalId: this.SmartStorage.get("proposalId"),
        isOnline: true,
        sourceCode: "",
      };
      if (item) {
        param.itemId = item.itemId
      }
      let _this = this;
      this.services
        .CheckCanNewItem(param)
        .then(function (res) {
          _this.checkCanNewItem = res.content;
          _this.SmartStorage.set("checkCanNewItem", res.content);
          flag && _this.editItem('');
          item && _this.checkCreateOrder(item);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkCreateOrder(item) {
      if (this.checkCanNewItem && this.checkCanNewItem.forbidden) {
        this.checkCanNewItem.tooltip
          ? this.$iToast(this.checkCanNewItem.tooltip)
          : this.$iToast("暂时不能提交订单哦");
        this.isloding = true;
        return false;
      }
      this.$iDelay(() => {
        this.SmartStorage.set("orderDetail", item);
        this.SmartStorage.set("onlineData", item.itsItemData);
        this.$router.push({
          path: "/onlinePlatformPriceCoupon",
          query: {
            params: item.itsItemData,
          },
        });
      });
    },
    async QueryOnlineOrder(clear) {
      this.setParams(this.searchDate);
      let params = this.params;
      params.pagerIndex = this.pageIndex;
      params.pagerSize = this.pageSize;
      params.Filter.Status = eval("(" + this.tabBar.CountExpr + ")").Status;
      params.Sort = { CreatedOn: -1, SortOrder: 1 };
      let _this = this;
      this.services.Paging(params).then(function (res) {
        _this.isFirst = false;
        if (res.success) {
          if (clear == true) {
            _this.dataList = [];
          }
          let eventList = res.content.rows;
          let buttons = [
            {
              Processor: "copyOrder",
              Txt: "复制订单",
            },
            {
              Processor: "rebackOrder",
              Txt: "一键返程",
            },
          ];
          for (let i = 0; i < eventList.length; i++) {
            eventList[i].itsItemData = JSON.parse(eventList[i].ItemData)
            eventList[i].Passenger_name = _this.StringExchangeEmoji(
              eventList[i].Passenger_name
            );
            console.log(eventList[i].itsItemData.isUseH5)
            _this.dataList.push(eventList[i]);
          }
          _this.total = res.content.total;
          _this.loadType = "";
          _this.isloding = true;
          if (
            eval("(" + _this.tabBar.CountExpr + ")").Status == 5 &&
            _this.dataList.length > 0 &&
            !_this.SmartStorage.get("viewOnly") &&
            _this.$cookies.get('tenant') == "msd"
          ) {
            _this.GetExtraBtns();
          }
        }
      });
    },
    GetExtraBtns(flag) {
      let _this = this;
      let params = this.dataList.map((data) => data.ItemId);
      this.services.GetExtraBtns(params).then(function (res) {
        if (res.success) {
          for (let i = 0; i < _this.dataList.length; i++) {
            if (params.some((item) => item == res.content[i].itemId)) {
              let btn = {
                Txt: "满意度调查",
                Processor: "goSurvayPage",
                status: res.content[i].status,
              };
              _this.dataList[i].ItsButtons.push(btn);
            } else {
              console.log(res.content[i]);
            }
          }

          console.log(_this.dataList);
        }
      });
    },
    callCarPhone(item, btn, index) {
      this.showCallBox(item)
    },
    useCarRemark(item, btn, index) {
      this.isCarRemarkDialog = true;
    },
    showUseCarRemark(item, btn, index) {
      this.isCarRemarkDialog = true;
      this.showBtn = false;
    },
    async SaveUseCarRemark(actDescr) {
      this.isloding = false;
      let params = {
        ItemId: this.clickItem.itemId,
        UseCarRemark: actDescr
      }
      let _this = this;
      this.services.SaveUseCarRemark(params).then(function (res) {
        if (res.success) {
          _this.showBtn = true;
          _this.isCarRemarkDialog = false;
          _this.StatByStatus(_this.searchDate);
        }
        _this.isloding = true;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.online_title{
  margin-top: .3rem;
  text-align: left;
  font-size: .2rem;
  font-weight: 500;
  padding-left: .2rem;
}
@import '@/assets/platform/index.scss'
</style>