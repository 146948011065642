<template>
  <div class="allOrderBox">
    <div class="item_title">
      <img class="item_title_img" :src="JSON.parse(item.ItemData).carIcon" alt="">
      <div class="item_title_txt">
        <p>{{ item.supplierName || item.SourceCodeTxt }}</p>
        <p>{{item.Departure_time| formatDate("yyyy-MM-dd hh:mm")}}</p>
      </div>
      <div class="item_title_right">
          <eventTag
            :statusTxt="item.statusTxt || item.ExtOrderStatusTxt"
            :status="item.status || item.Status"></eventTag>
          <div class="item_title_price">报价<span class="bold">￥{{ [100,24].includes(item.ExtOrderStatus) ? item.SettleAmount : item.BudgetAmount}}</span></div>
          
      </div>
      
    </div>
    <div class="item_address">
      <div class="item_address_start">
        <i class="greenBc"></i>
        {{ item.startName || item.Addr_start }}
      </div>
      <!-- <div class="item_address_circle">
        <div class="color_lightcoral"></div>
        <div></div>
        <div></div>
        <div></div>
        <div class="color_lightcoral"></div>
      </div> -->
      <div class="item_address_end">
        <i class="orangeBc"></i>
        {{ item.endName || item.Addr_end }}
      </div>
    </div>
    <div class="item_info">
      <div class="item_info_list">
        <p>乘车联系人<span>{{item.Passenger_name}} {{item.Passenger_phone}}</span></p> 
      </div>
      <div class="item_info_list">
          <p>订单创建人<span>{{item.Creator_DspName}}</span></p> 
      </div>
    </div>


    <template v-if="item.ItsButtons">
      <div
        :class="['btnList', item.ItsButtons.length > 3 && 'gaodeBtns']"
        v-if="!this.SmartStorage.get('viewOnly')"
      >
        <div class="dropDownBox" v-if="isDrop">
          <template v-if="item.ItsButtons.length > 3">
            <span
              v-for="(btn1, bindex1) in item.ItsButtons.slice(
                3,
                item.ItsButtons.length
              )"
              :key="bindex1"
              @click.stop="isloding && btnClick(item, btn1, bindex1)"
              >{{ btn1.Txt }}</span
            >
          </template>
        </div>
        <div class="more-btn">
          <div
          v-if="item.ItsButtons.length > 3"
          @click.stop="isDrop = !isDrop"
          >更多</div>
        </div>
        <div>
          <a
          :class="[
            btn.Processor,
            item.ItsButtons.length > 3
              ? 'btnLength3'
              : 'btnLength' + item.ItsButtons.length,
            !isloding && 'isloding',
            (btn.status == 1 || btn.status == 3) && 'survayed',
          ]"
          v-for="(btn, bindex) in item.ItsButtons.slice(0, 3)"
          :key="bindex"
          @click.stop="isloding && btnClick(item, btn, index)"
        >
          {{ btn.Txt }}</a
        >
        </div>
        

      </div>
    </template>
  </div>
</template>

<script>
import eventTag from "@/views/common/eventTag";
export default {
  props: [
    "item",
    "showCallBox",
    "btnClick",
    "isloding",
    "dropDown",
    "index",
    "tabBar",
  ],
  data() {
    return {
      boxShadow: "",
      background: "",
      color: "",
      isDrop: false,
    };
  },
  watch: {
    dropDown: {
      handler(newValue, oldValue) {
        this.isDrop = false;
      },
    },
  },
  components: {
    eventTag,
  },
  mounted() {
    document.addEventListener("click", this.handleClick);
  },
  methods: {
    handleClick(e) {
      let event = e || window.event;
      const target = event.target;
      let dropDownBox=document.getElementsByClassName("dropDownBox")[0];
      if(dropDownBox&&!dropDownBox.contains(target)){
        this.isDrop = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/msd/carOrderItem.scss';
</style>