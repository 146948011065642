var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"allOrderBox"},[_c('div',{staticClass:"item_title"},[_c('img',{staticClass:"item_title_img",attrs:{"src":JSON.parse(_vm.item.ItemData).carIcon,"alt":""}}),_c('div',{staticClass:"item_title_txt"},[_c('p',[_vm._v(_vm._s(_vm.item.supplierName || _vm.item.SourceCodeTxt))]),_c('p',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.item.Departure_time,"yyyy-MM-dd hh:mm")))])]),_c('div',{staticClass:"item_title_right"},[_c('eventTag',{attrs:{"statusTxt":_vm.item.statusTxt || _vm.item.ExtOrderStatusTxt,"status":_vm.item.status || _vm.item.Status}}),_c('div',{staticClass:"item_title_price"},[_vm._v("报价"),_c('span',{staticClass:"bold"},[_vm._v("￥"+_vm._s([100,24].includes(_vm.item.ExtOrderStatus) ? _vm.item.SettleAmount : _vm.item.BudgetAmount))])])],1)]),_c('div',{staticClass:"item_address"},[_c('div',{staticClass:"item_address_start"},[_c('i',{staticClass:"greenBc"}),_vm._v(" "+_vm._s(_vm.item.startName || _vm.item.Addr_start)+" ")]),_c('div',{staticClass:"item_address_end"},[_c('i',{staticClass:"orangeBc"}),_vm._v(" "+_vm._s(_vm.item.endName || _vm.item.Addr_end)+" ")])]),_c('div',{staticClass:"item_info"},[_c('div',{staticClass:"item_info_list"},[_c('p',[_vm._v("乘车联系人"),_c('span',[_vm._v(_vm._s(_vm.item.Passenger_name)+" "+_vm._s(_vm.item.Passenger_phone))])])]),_c('div',{staticClass:"item_info_list"},[_c('p',[_vm._v("订单创建人"),_c('span',[_vm._v(_vm._s(_vm.item.Creator_DspName))])])])]),(_vm.item.ItsButtons)?[(!this.SmartStorage.get('viewOnly'))?_c('div',{class:['btnList', _vm.item.ItsButtons.length > 3 && 'gaodeBtns']},[(_vm.isDrop)?_c('div',{staticClass:"dropDownBox"},[(_vm.item.ItsButtons.length > 3)?_vm._l((_vm.item.ItsButtons.slice(
              3,
              _vm.item.ItsButtons.length
            )),function(btn1,bindex1){return _c('span',{key:bindex1,on:{"click":function($event){$event.stopPropagation();_vm.isloding && _vm.btnClick(_vm.item, btn1, bindex1)}}},[_vm._v(_vm._s(btn1.Txt))])}):_vm._e()],2):_vm._e(),_c('div',{staticClass:"more-btn"},[(_vm.item.ItsButtons.length > 3)?_c('div',{on:{"click":function($event){$event.stopPropagation();_vm.isDrop = !_vm.isDrop}}},[_vm._v("更多")]):_vm._e()]),_c('div',_vm._l((_vm.item.ItsButtons.slice(0, 3)),function(btn,bindex){return _c('a',{key:bindex,class:[
          btn.Processor,
          _vm.item.ItsButtons.length > 3
            ? 'btnLength3'
            : 'btnLength' + _vm.item.ItsButtons.length,
          !_vm.isloding && 'isloding',
          (btn.status == 1 || btn.status == 3) && 'survayed' ],on:{"click":function($event){$event.stopPropagation();_vm.isloding && _vm.btnClick(_vm.item, btn, _vm.index)}}},[_vm._v(" "+_vm._s(btn.Txt))])}),0)]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }